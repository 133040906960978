import React, { useState, useEffect } from 'react';
import { FaWhatsapp, FaPhone } from 'react-icons/fa';

const FloatingButtons = () => {
    const [isPulsing, setIsPulsing] = useState(false);

    useEffect(() => {
        const pulseTimeout = setTimeout(() => {
            setIsPulsing(true);
        }, 1000);

        return () => clearTimeout(pulseTimeout);
    }, []);

    const trackButtonClick = (eventCategory, eventAction) => {
        if (window.gtag) {
            window.gtag('event', eventAction, {
                'event_category': eventCategory,
                'event_label': eventAction,
                'send_to': 'AW-16721393829',  // Your Google Ads ID
            });
        }
    };

    const buttonClasses = `
        p-4 rounded-full shadow-lg text-white
        transition-all duration-300 ease-in-out
        hover:scale-110 hover:shadow-xl
        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-opacity-50
    `;

    return (
        <div className="fixed bottom-16 right-6 flex flex-col space-y-4 z-50">
            <a
                href="https://wa.me/971561868236?text=Hello!"
                target="_blank"
                rel="noopener noreferrer"
                className={`${buttonClasses} bg-green-500 focus:ring-green-400 ${isPulsing ? 'animate-pulse' : ''}`}
                onClick={() => trackButtonClick('WhatsApp Button', 'click_whatsapp')}
            >
                <FaWhatsapp className="w-8 h-8" />
            </a>

            <a
                href="tel:+971561868236"
                className={`${buttonClasses} bg-cyan-500 focus:ring-blue-400`}
                onClick={() => trackButtonClick('Phone Button', 'click_phone')}
            >
                <FaPhone className="w-8 h-8" />
            </a>
        </div>
    );
};

export default FloatingButtons;
